import React, { useState } from "react"
import { Popover } from "@mui/material"
import styled from 'styled-components'
import bnz_coin_icon from '../../../images/coin.svg'
import './styles.css'
import { ReactComponent as CoinIcon } from '../../../images/coin.svg'


const BnzCoin = styled.img`
  width: 53px;
  height: 53px;
  box-sizing: border-box;
  cursor: pointer;
`

const CreditsBalloon = (props) => {
  const { clientWallet, clientSubscription,clientSubscriptionIdentifier } = props

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
    { clientSubscription && (clientSubscriptionIdentifier === 'plano-essencial' || clientSubscriptionIdentifier === 'plano-start') ? (

      <div className="credits-balloon-wrapper">
        <div className="button-credits-balloon">
          <BnzCoin src={bnz_coin_icon} alt="Moeda B.onuz" onClick={handleClick} />
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div className="credits-balloon-container">
            <div className="credits-ballon-header">
              <CoinIcon style={{ width: '32px', height: '32px', marginRight: '20px' }} />
              {/* {loading ? <CustomSpinner /> : data} */}
              <p>Meus créditos</p>
            </div>
            <div className="credits-ballon-content">
              <div>
                <span style={{ color: '#4E4B59' }}>{clientSubscriptionIdentifier === 'plano-essencial' ? 'Essencial' : 'Start'}</span>
                <p style={{ color: '#4E4B59' }}>*por mês e não cumalativo</p>
              </div>
              <span style={{ color: '#7683F6' }}>{clientWallet ? clientWallet.wallet.creditsBonuzPlans : 0} BNZs</span>
            </div>
            <div className="credits-ballon-content">
              <div>
                <span style={{ color: '#4E4B59' }}>Adquiridos</span>
                <p style={{ color: '#4E4B59' }}>*fora do plano mensal</p>
              </div>
              <span style={{ color: '#7683F6' }}>{clientWallet ? clientWallet.wallet.saldoFinal || 0 : 0} BNZs</span>
            </div>
          </div>
        </Popover>
      </div>
    ) : ''
  }
    </>
  )
} 

export default CreditsBalloon
